<template>
  <a-spin :spinning="pageLoaidng">

    <a-form :model="form"
            :colon="false"
            :labelCol="{style: 'width: 96px;'}">
      <!-- 邀请排行榜 -->
      <a-form-item label="邀请排行榜">
        <a-switch v-model:checked="form.ranking.status"
                  checked-children="开"
                  un-checked-children="关"
                  @change="saveSetting('ranking', true)" />

        <span class="ml-24 mr-12 text-color-999">直播间显示邀请排行榜，激励用户分享。</span>

        <a-popover placement="right">
          <template #content>
            <img src="@/assets/ranking_example.png"
                 alt="邀请排行榜示例图"
                 style="width: 250px">
          </template>
          <a-button type="link">
            查看示例
          </a-button>
        </a-popover>
      </a-form-item>
    </a-form>
  </a-spin>
</template>

<script>
import { defineComponent, ref, reactive, watch, onMounted } from "vue";
import _ from "lodash";
import { Switch, Popover, message } from "ant-design-vue";
import { useRoute } from "vue-router";

import liveApi from "@/service/api/live";

export default defineComponent({
  name: "InteractionSetting",
  props: {
    liveRoom: {
      type: Object,
      default: () => {},
      required: true,
    },
    config: {
      type: [Array, String],
      default: () => [],
    },
  },
  emits: [],
  components: {
    ASwitch: Switch,
    APopover: Popover,
  },
  setup(props) {
    const pageLoaidng = ref(false);

    const route = new useRoute();
    const { id: liveRoomId } = route.query;

    const form = reactive({
      ranking: {
        status: false,
        name: "ranking",
      },
    });

    // 初始化数据
    const configEnum = {
      ranking: "ranking",
    };

    const initConfig = (config) => {
      _.forEach(config, (option) => {
        const _option = _.cloneDeep(option);
        _.assign(_option, _option.value);
        delete _option.value;

        const itemName = configEnum[_option.name];
        _.assign(form[itemName], _option);
      });

      pageLoaidng.value = false;
    };

    watch(
      () => props.config,
      (value) => {
        if (!value || !value.length) {
          pageLoaidng.value = false;
          return;
        }

        initConfig(value);
      },
      { immediate: true }
    );

    // 保存配置
    const saveSetting = async (type, isAlert) => {
      const target = form[configEnum[type]];
      const params = _.cloneDeep({
        ...target,
        liveRoomId,
      });

      await liveApi
        .settingSave(params)
        .then((res) => {
          target.id = res.id;
          if (isAlert) {
            message.success("保存成功");
          }
        })
        .catch(() => {
          target.status = !target.status;
        });
    };

    onMounted(() => {});
    return {
      pageLoaidng,
      form,
      saveSetting,
    };
  },
});
</script>
<style lang='less' scoped>
</style>